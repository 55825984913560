<template>
	<div class="artice_top widthCen content">
		<h6 @click="$router.back(-1)" style="cursor: pointer;">
			学车视频 > 科目二 >
			<span>视频播放</span>
		</h6>
		<div class="article_white">
			<div class="details">
				<div class="details_box"><video style="width:100%;" :src="model.VideoUrl" controls="controls"></video></div>
				<h5>{{ model.Title }}</h5>

				<div class="introduce" v-html="model.Content"></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/artice.scss';

.details {
	h5 {
		font-size: 22px;
		color: #333;
		width: 750px;
		margin: 30px auto;
	}

	.introduce {
		overflow: hidden;
		width: 90%;
		margin: 0 auto;
		border-top: solid 1px #f0f0f0;

		h1 {
			color: #333;
			font-size: 18px;
			font-weight: normal;
			padding-left: 14px;
			position: relative;
			margin-top: 18px;
		}

		h1::after {
			content: '';
			width: 3px;
			height: 22px;
			background-color: #ff470f;
			position: absolute;
			left: 0px;
			top: 0px;
			z-index: 10;
		}

		p {
			font-size: 14px;
			color: #333;
			line-height: 28px;
			margin: 20px auto;
		}
	}
}
</style>
<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			model: {
				Title: '',
				Content: '',
				VideoUrl: ''
			}
		};
	},
	created() {
		var id = this.$route.query.id;
		this.getInfo(id);
	},
	methods: {
		details() {
			this.$router.push('articleDetails');
		},
		getInfo(id) {
			this.$api.getVideoInfo(id).then(res => {
				this.model = res.data;
			});
		}
	}
};
</script>
